<template>
    <div class="inner_pages" id="checkout_orders">
        <sidebar-component v-model="showSidebar"/>
        <div class="container">
            <div class="respective_content">
                <div class="acton_header m-0">
                    <button type="button" class="bar_menu" @click="showSidebar = !showSidebar"><img src="@/assets/images/bar_menu.svg"></button>
                    <h2>Orders</h2>
                    <div class="search_area">
                        <input type="text" v-model.trim="params.search" placeholder="Search by customer name, email" @input="isTyping = true" />
                        <button type="button" class="search_btn"><i class="fas fa-search"></i></button>
                        <a class="cleaner" @click="params.search = ''; isTyping = true;" v-if="searchField && params.search"><i class="fas fa-times"></i></a>
                    </div>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                </div>

                <div class="result_wpr new">
                    <div class="actions mb-0">
                        <ul class="left">
                            <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                <label>Tutorials</label><img src="@/assets/images/play.svg">
                                <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                    <ul>
                                        <li @click="tutorial = true;">How to use the orders feature</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="optionDrops contacts-tabs capitalize" @click="orderType = !orderType" v-click-outside="closeOrderType">
                                {{ params.order_type == 'all' ? `${params.order_type} transactions` : params.order_type }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="orderType ? 'active' : ''">
                                    <ul>
                                        <li @click="handleOrderTypeFilter('all')">All Transactions</li>
                                        <li @click="handleOrderTypeFilter('products')">Products</li>
                                        <li @click="handleOrderTypeFilter('bumps')">Bumps</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs fixed_height" @click="productType = !productType" v-click-outside="closeProductType">
                                {{ productFilterName ? productFilterName : (params.order_type == 'bumps' ? 'Select Bump' : 'Select Product') }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="productType ? 'active' : ''">
                                    <ul>
                                        <li @click="handleFilterByProduct('select_order')">{{ params.order_type == 'bumps' ? 'Select Bump' : 'Select Product'}}</li>
                                        <li v-for="(product, p) in productFilterList" :key="p" @click="handleFilterByProduct(product)">{{ product.has_bump ? product.bump_name : product.name }}</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs fixed_height duration" @click="handleDurationType()" v-click-outside="closeDurationType">
                                {{ durationTitle }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="durationType ? 'active' : ''">
                                    <ul>
                                        <li v-for="(duration, d) of durations" :key="d" @click="handleDuration(duration)">{{ duration }}</li>
                                    </ul>
                                </div>
                                <div class="custom_range" :class="{'active': isCustomRange }" @click.stop="event && event.stopPropagation ? event.stopPropagation() : ''">
                                    <h4>Custom Range</h4>
                                    <ul>
                                        <li>
                                            <label for="">Start Date</label>
                                            <datepicker v-model="params.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <label for="">End Date</label>
                                            <datepicker v-model="params.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <button type="button" class="btn save_btn" @click="handleCustomDateRange()">Apply</button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="filter_btn" @click="mobile_filter = true;">
                                <span></span>
                                <span></span>
                                <span></span>
                            </li>
                            <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                                <input type="text" v-model.trim="params.search" placeholder="Search by customer name, email" @input="isTyping = true" />
                                <button type="button" class="search_btn" @click="searchField = !searchField;"><i class="fas fa-search"></i></button>
                                <a class="cleaner" @click="params.search = ''; isTyping = true;" v-if="searchField && params.search"><i class="fas fa-times"></i></a>
                            </li> -->
                        </ul>
                    </div>
                    <div class="list_wpr">
                        <ul class="order_list">
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Gross Revenue</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ OrdersAnalytics.total_gross_revenue ? parseFloat(OrdersAnalytics.total_gross_revenue).toFixed(2) : '0.00' }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Gross Refunds</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ OrdersAnalytics.total_gross_refund ? parseFloat(OrdersAnalytics.total_gross_refund).toFixed(2) : '0.00' }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Sale Tax</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ OrdersAnalytics.total_sale_tax ? parseFloat(OrdersAnalytics.total_sale_tax).toFixed(2) : '0.00' }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Net Revenue</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ OrdersAnalytics ? (parseFloat(OrdersAnalytics.total_gross_revenue) - parseFloat(OrdersAnalytics.total_sale_tax) - parseFloat(OrdersAnalytics.total_gross_refund)).toFixed(2) : '0.00' }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Total Transactions</h5>
                                        <h3>{{ OrdersAnalytics.total_transactions ? OrdersAnalytics.total_transactions : 0 }}</h3>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-shopping-bag"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Total Customers</h5>
                                        <h3>{{ OrdersAnalytics.total_customers ? OrdersAnalytics.total_customers : 0 }}</h3>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Average Order Value</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ getAvarageOrderValue() }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Refunded Customers</h5>
                                        <h3>{{ OrdersAnalytics.total_refunded_customers ? OrdersAnalytics.total_refunded_customers : 0 }}</h3>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-user"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Refunds</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ OrdersAnalytics.total_gross_refund ? parseFloat(OrdersAnalytics.total_gross_refund).toFixed(2) : 0 }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Refunds Rate</h5>
                                        <h3>{{ getRefundRate() }}</h3>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-percentage"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="header_filter" :class="{'show' : mobile_filter}">
                    <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                    <div class="action_area">
                        <h3 class="sub_heading2 mb-4">Filters</h3>
                        <ul>
                            <li class="optionDrops contacts-tabs capitalize" @click="orderType2 = !orderType2" v-click-outside="closeOrderType2">
                                {{ params.order_type == 'all' ? `${params.order_type} transactions` : params.order_type }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="orderType2 ? 'active' : ''">
                                    <ul>
                                        <li @click="handleOrderTypeFilter('all')">All Transactions</li>
                                        <li @click="handleOrderTypeFilter('products')">Products</li>
                                        <li @click="handleOrderTypeFilter('bumps')">Bumps</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs" @click="productType2 = !productType2" v-click-outside="closeProductType2">
                                {{ productFilterName ? productFilterName : (params.order_type == 'bumps' ? 'Select Bump' : 'Select Product') }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="productType2 ? 'active' : ''">
                                    <ul>
                                        <li @click="handleFilterByProduct('select_order')">{{ params.order_type == 'bumps' ? 'Select Bump' : 'Select Product'}}</li>
                                        <li v-for="(product, p) in productFilterList" :key="p" @click="handleFilterByProduct(product)">{{ product.has_bump ? product.bump_name : product.name }}</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs fixed_height" @click="handleDurationType2()" v-click-outside="closeDurationType2">
                                {{ durationTitle }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="durationType2 ? 'active' : ''">
                                    <ul>
                                        <li v-for="(duration, d) of durations" :key="d" @click="handleDuration(duration)">{{ duration }}</li>
                                    </ul>
                                </div>
                                <div class="custom_range" :class="{'active': isCustomRange }" @click.stop="event && event.stopPropagation ? event.stopPropagation() : ''">
                                    <h4>Custom Range</h4>
                                    <ul>
                                        <li>
                                            <label for="">Start Date</label>
                                            <datepicker v-model="params.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <label for="">End Date</label>
                                            <datepicker v-model="params.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <button type="button" class="btn save_btn" @click="handleCustomDateRange()">Apply</button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <transaction-component :order-params="params" :transaction-watcher="transactionWatcher"></transaction-component>
            </div>
        </div>
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapActions, mapState } from 'vuex'

    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))
    const SidebarComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Sidebar'))
    const TransactionComponent = defineAsyncComponent(() => import('@/pages/checkout/components/order/Transaction'))

    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Orders',

        data () {
            return {
                durationTitle: 'Today',
                durations: [
                    'Today',
                    'Yesterday',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days',
                    'This Month',
                    'Last Month',
                    'This Year',
                    'All Time',
                    'Custom Range',
                ],
                maxStartDate: '',
                minEndDate: '',
                tutorialList: false,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/420620f446c04a6cad7ac8efab7a96e6?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                moment,
                orderType: false,
                orderType2: false,
                productType: false,
                productType2: false,
                durationType: false,
                durationType2: false,
                searchField: false,
                params: {
                    order_type: 'all',
                    product_id: 0,
                    bump_id: 0,
                    start_date:  moment.utc().format('YYYY-MM-DD'),
                    end_date:  moment.utc().format('YYYY-MM-DD'),
                    search: '',
                    include_funnel: 0,
                },
                isCustomRange: false,
                transactionWatcher: 0,
                isTyping: false,
                productFilterList: [],
                selectedTransactionProduct: 'select_order',
                productFilterName: '',
                mobile_filter: false,
                showSidebar: false,
            }
        },

        components:{
            SidebarComponent,
            VideoTutorial,
            TransactionComponent,
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                vm.params.page = 1;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null && (vm.params.search.length >= 2 || vm.params.search.length == 0)) {
                        vm.transactionWatcher++;
                    }
                }
            },

            allOrderTransactions (val) {
                const vm = this;

                vm.handleOrderTransactionsFilter();
            },
        },

        computed: mapState ({
            OrdersAnalytics: state => state.checkoutModule.OrdersAnalytics,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
            allOrderTransactions: state => state.checkoutModule.allOrderTransactions,
        }),

        mounted () {
            const vm = this;

            vm.getOrdersAnalytics(vm.params);

            if (!vm.allOrderTransactions.length) {
                vm.getAllOrderTransactions();
            }

            vm.handleOrderTransactionsFilter();
        },

        methods:{
            ...mapActions({
                getOrdersAnalytics: 'checkoutModule/getOrdersAnalytics',
                getAllOrderTransactions: 'checkoutModule/getAllOrderTransactions',
            }),

            handleDuration (duration) {
                const vm = this;

                setTimeout(() => {
                    vm.durationTitle = duration;

                    if (duration == 'Custom Range') {
                        vm.isCustomRange = !vm.isCustomRange;
                    } else {
                        const filter = Helper.filterDates(duration);

                        vm.params.start_date = filter.start_date;
                        vm.params.end_date   = filter.end_date;
                        vm.isCustomRange     = false;

                        vm.getOrdersAnalytics(vm.params);
                        vm.transactionWatcher++;
                    }
                }, 50);
            },

            handleSelectDuration () {
                const vm = this;

                vm.isCustomRange = vm.durationTitle == 'Custom Range' ? !vm.isCustomRange : vm.isCustomRange;
            },

            handleCustomDateRange () {
                const vm = this;

                vm.isCustomRange     = false;
                vm.params.start_date =  moment(vm.params.start_date).isValid() ? moment(vm.params.start_date).format('YYYY-MM-DD') : '';
                vm.params.end_date   =  moment(vm.params.end_date).isValid() ? moment(vm.params.end_date).format('YYYY-MM-DD') : '';

                vm.transactionWatcher++;
            },

            handleResetParams () {
                const vm = this;

                vm.params.product_id = 0;
                vm.params.bump_id = 0;
                vm.params.search = '';
                vm.params.include_funnel = 0;

                vm.transactionWatcher++;
            },

            getRefundRate () {
                const vm = this;

                const refundCount = vm.OrdersAnalytics.total_refund_count ? parseFloat(vm.OrdersAnalytics.total_refund_count) : 0;
                const orderCount  = vm.OrdersAnalytics.total_transactions ? parseFloat(vm.OrdersAnalytics.total_transactions) : 0;
                const refundRate  = (refundCount * 100) / orderCount;

                return refundRate && refundRate != 'Infinity' ? (refundRate).toFixed(2) : 0;
            },

            getAvarageOrderValue () {
                const vm = this;

                const totalAmount        = vm.OrdersAnalytics.total_gross_revenue ? parseFloat(vm.OrdersAnalytics.total_gross_revenue) : 0;
                const totalOrders        = vm.OrdersAnalytics.total_transactions ? parseFloat(vm.OrdersAnalytics.total_transactions) : 0;
                const avarageOrderValue  = totalAmount / totalOrders;

                return avarageOrderValue && avarageOrderValue != 'Infinity' ? (avarageOrderValue).toFixed(2) : 0;
            },

            handleOrderTransactionsFilter () {
                const vm = this;

                vm.selectedTransactionProduct = 'select_order';

                if (vm.allOrderTransactions.length) {
                    let productList = JSON.parse(JSON.stringify(vm.allOrderTransactions));

                    if (vm.params.order_type == 'all') {
                        vm.productFilterList = productList;
                    } else if (vm.params.order_type == 'products') {
                        vm.productFilterList = productList.filter(({ has_bump }) => has_bump == 0);
                    } else if (vm.params.order_type == 'bumps') {
                        vm.productFilterList = productList.filter(({ has_bump }) => has_bump == 1);
                    }
                }
            },

            handleFilterByProduct (product) {
                const vm = this;

                if (product == 'select_order') {
                    vm.params.product_id = 0;
                    vm.params.bump_id    = 0;
                    vm.productFilterName = '';
                }

                if (product.has_bump) {
                    vm.params.bump_id    = product.bump_id;
                    vm.params.product_id = 0;
                    vm.productFilterName = product.bump_name;
                } else {
                    vm.params.product_id = product.product_id;
                    vm.params.bump_id    = 0;
                    vm.productFilterName = product.name;
                }

                vm.getOrdersAnalytics(vm.params);
                vm.transactionWatcher++;
            },

            closeOrderType () {
                const vm = this;

                vm.orderType = false;
            },

            closeOrderType2 () {
                const vm = this;

                vm.orderType2 = false;
            },

            closeProductType () {
                const vm = this;

                vm.productType = false;
            },

            closeProductType2 () {
                const vm = this;

                vm.productType2 = false;
            },

            closeDurationType () {
                const vm = this;

                vm.durationType  = false;
                vm.isCustomRange = false;
            },
            closeDurationType2 () {
                const vm = this;

                vm.durationType2  = false;
                vm.isCustomRange = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            handleOrderTypeFilter (type) {
                const vm = this;

                vm.params.order_type = type;
                vm.transactionWatcher++;

                vm.getOrdersAnalytics(vm.params);
            },

            handleDurationType () {
                const vm = this;

                if (!vm.durationType && vm.isCustomRange) {
                    vm.isCustomRange = false;
                }

                vm.durationType = !vm.durationType;
            },

            handleDurationType2 () {
                const vm = this;

                if (!vm.durationType2 && vm.isCustomRange) {
                    vm.isCustomRange = false;
                }

                vm.durationType2 = !vm.durationType2
            }
        }
    }
</script>

<style scoped>
    .bar_menu {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 0 !important;
        display: none;
    }
    .container{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .actions > ul li.duration{
        position: relative;
    }
    .actions > ul li.duration .dropdown_wpr{
        left: auto;
        right: 1px;
    }
    .result_wpr.new{
        overflow: visible;
    }
    .custom_range {
        text-align: left;
        background: #fff;
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        margin-top: 1px;
        display: none;
        opacity: 0;
        z-index: 1;
    }
    .custom_range.active{
        animation: dropMove 0.5s ease-in-out;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: block;
        opacity: 1;
    }
    .custom_range:before{
        content: '';
        position: absolute;
        right: 17px;
        top: -6px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #E0E0E0;
        border-left: 1px solid #E0E0E0;
        background: #fff;
        transform: rotate(45deg);
    }
    .custom_range h4{
        padding: 10px 15px;
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        border-bottom: 1px solid #ececec;
    }
    .custom_range ul{
        display: flex;
        flex-direction: column;
        margin: 10px 0 15px;
        line-height: 0;
        gap: 12px;
    }
    .custom_range ul li{
        border: 0;
        padding: 5px 15px;
        height: auto;
        margin: 0;
        width: 100%;
        display: block;
    }
    .custom_range ul li label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 3px;
        display: block;
        justify-content: flex-start !important;
    }
    .custom_range ul li :deep(input){
        width: 100%;
        border: 1px solid #cdcdcd;
        height: 30px;
        border-radius: 3px;
        padding: 0 5px 0 10px;
        color: #808080;
        font-size: 12px;
        line-height: 15px;
    }
    .custom_range .save_btn{
        padding: 7px 15px;
        background: #2f7eed;
        color: #fff;
        width: 100%;
        border-radius: 3px;
        cursor: pointer;
        font-size: 12px;
        line-height: 14px;
    }

    .widgets_wpr{
        padding: 0;
        background: transparent;
        border: 0;
        box-shadow: none;
        height: auto;
    }
    .widgets_wpr ul{
        width: 100%;
        justify-content: flex-start;
    }
    .widgets_wpr ul li{
        height: 42px;
        background: #fff;
    }
    .widgets_wpr ul li.checkbox_wpr{
        border: 0;
        width: auto;
        display: flex;
        align-items: center;
        margin: 0 15px;
    }
    .widgets_wpr li.search{
        flex: 0 1 400px;
        width: 100%;
        padding: 0 70px 0 15px;
        margin-left: auto;
    }
    .widgets_wpr li.search input[type = text]{
        height: 40px;
        width: 100%;
        background: transparent;
    }
    .widgets_wpr li.search button{
        width: 40px;
        height: 42px;
        background: #2c3e50;
        font-size: 16px;
        color: #d9d9d9;
        border-radius: 0 3px 3px 0;
        position: absolute;
        right: 0;
        top: -1px;
        cursor: pointer;
    }
    li.search_area .cleaner{
        position: absolute;
        right: 30px;
        top: 0;
        height: 33px;
        width: 30px;
        border: 0;
        z-index: 5;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
    }
    .list_wpr{
        overflow: hidden;
    }
    .order_list{
        list-style-type: none;
        margin: 0 -10px 30px -10px;
        display: flex;
        flex-wrap: wrap;
    }
    .order_list li{
        flex: 0 1 20%;
        padding: 10px;
    }
    .order_card{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 25px 20px;
        /* gap: 20px; */
        /* box-shadow: 0px 1px 5px rgba(0,0,0,0.08); */
        border-radius: 10px;
        /* border: 1px solid #e9e9e9; */
        background: #fff;
        text-align: left;
    }
    .order_card .icon{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        color: #ababab;
        font-size: 15px;
    }
    .order_card h3{
        font-size: 24px;
        line-height: 30px;
        color: #121525;
        font-weight: 300;
        margin-top: 7px;
    }
    .order_card h5{
        font-size: 13px;
        line-height: 19px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .actions > ul li.order_amt {
        flex: 0 1 80px;
    }
    .actions > ul li.order_amt input[type=number]{
        width: 100%;
        -moz-appearance: textfield;
    }
    .actions > ul li.order_amt input[type=number]::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: 50px;
        padding: 20px;
        text-align: left;
    }
    .result_wpr table td h4{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .result_wpr table td h4 span{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        background: #e9e9e9;
        padding: 3px 8px;
        border-radius: 10px;
        display: inline-block;
    }
    .result_wpr table td h6{
        font-size: 11px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
    }
    .result_wpr table td .action_list{
        display: flex;
        justify-content: center;
        gap: 15px;
    }
    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
        cursor: pointer;
    }

    .order_card .icon h4 {
        display: block;
        margin-block-start: 20px;
        margin-block-end: 20px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        font-size: 18px;
    }
    .capitalize{
        text-transform: capitalize;
    }
    .fixed_height .dropdown_wpr{
        z-index: 2;
    }
    .fixed_height .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: overlay;
    }
    .fixed_height .dropdown_wpr ul::-webkit-scrollbar{
        width: 2px;
    }
    .fixed_height .dropdown_wpr ul::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #dbdbdb;
    }
    .result_wpr.new .actions > ul li.breaker{
        padding: 0;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    @media(max-width: 1599px){
        .order_list li {
            flex: 0 1 25%;
            padding: 15px;
        }
    }
    @media(max-width: 1399px){
        .order_list li {
            padding: 10px;
        }
        .order_card{
            padding: 20px 15px;
        }
        .order_card h5 {
            font-size: 11px;
            line-height: 16px;
        }
        .order_card h3 {
            font-size: 20px;
            line-height: 25px;
        }
    }
    @media(max-width: 1199px){
        .order_list li {
            flex: 0 1 33.333%;
        }
        .bar_menu {
            display: block;
        }
    }
    @media(max-width: 767px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn), .acton_header .right_section .switch_option{
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 699px){
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
        }
    } */
    @media(max-width: 650px){
        .order_list li {
            flex: 0 1 50%;
        }
    }
    /* @media(max-width: 600px){
        .result_wpr.new .actions{
            margin-top: 15px;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .result_wpr.new .actions > ul.right{
            width: 100%;
            margin-top: 10px;
        }
        .result_wpr.new .actions > ul.right li:first-child{
            margin-right: auto;
        }
    }
    @media(max-width: 460px){
        .result_wpr.new .actions > ul li.breaker{
            width: 100%;
        }
        .custom_range{
            left: 1px;
            right: auto;
        }
        .custom_range:before{
            right: 42%;
        }
    } */

    @media(max-width: 425px){
        .order_list li {
            flex: 0 1 100%;
        }
    }
    @media(min-width: 768px){
        .header_filter{
            display: none;
        }
    }
</style>